<template>
  <div class="box">
    <div>
      <div style="margin-top: 20px; padding-bottom: 15px">
        <div v-for="item in sfcList" :key="item.id">
          <div class="list-item" v-if="item.userTypes == '1'">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div>
                <div style="font-size: 16px; font-weight: 600">
                  {{ item.islong == 1 ? time2(item.tripDate) : '每天' }} {{ item.tripTime }}
                  <span
                    :style="
                      item.status == 3
                        ? 'background:gray;'
                        : (!formatDate(item.tripDate)) || (item.islong != 1)
                        ? 'background:green;'
                        : 'background:burlywood;'
                    "
                    style="
                      font-size: 12px;
                      color: #fff;
                      padding: 1px 5px;
                      border-radius: 3px;
                    "
                    >{{
                      item.status == 3
                        ? "已取消"
                        : (!formatDate(item.tripDate)) || (item.islong != 1)
                        ? "进行中"
                        : "已过期"
                    }}</span
                  >
                </div>
                <div>
                  <span style="margin-right: 5px"
                    ><van-tag type="primary"
                      >{{ item.passengerNum }}人</van-tag
                    ></span
                  >
                  <span style="margin-right: 5px"
                    ><van-tag type="success">{{
                      item.carpooling == 1 ? "愿拼座" : "不拼座"
                    }}</van-tag></span
                  >
                  <span style="margin-right: 5px"
                    ><van-tag type="danger">{{
                      item.luggage == 1 ? "无大件行李" : "有大件行李"
                    }}</van-tag></span
                  >
                  <span
                    ><van-tag type="warning">{{ item.islong == 1 ? '单次行程' : '长期行程' }}</van-tag></span
                  >
                </div>
              </div>
              <div
                style="font-size: 22px; font-weight: 500"
                v-if="item.fee != null"
              >
                {{ item.fee
                }}<span style="font-size: 12px; margin-left: 2px">元</span>
              </div>
              <div style="font-size: 18px; font-weight: 600" v-else>面议</div>
            </div>
            <div
              style="
                margin-top: 15px;
                padding-bottom: 10px;
                border-bottom: 1px solid #f2f2f2;
              "
            >
              <div style="display: flex; font-size: 12px">
                <div>
                  <van-icon
                    name="location-o"
                    style="font-size: 14px; margin-right: 3px"
                  />起点：
                </div>
                <div>{{ item.startAddress }}</div>
              </div>
              <div
                style="
                  width: 95%;
                  margin-left: 5%;
                  height: 1px;
                  /* background: #f2f2f2; */
                  margin-top: 5px;
                "
              ></div>
              <div style="display: flex; font-size: 12px; margin-top: 5px">
                <div>
                  <van-icon
                    name="location-o"
                    style="font-size: 14px; margin-right: 3px"
                  />终点：
                </div>
                <div>{{ item.endAddress }}</div>
              </div>
            </div>
            <div
              style="
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 10px; ;
              "
            >
            <div
                class="cklx"
                @click="shelTrip(item)"
                style="margin-right: 5px"
                v-if="(!formatDate(item.tripDate)) || (item.islong != 1)"
              >
                分享行程
              </div>
              <div
                class="cklx"
                @click="editTrip(item)"
                style="margin-right: 5px"
                v-if="(!formatDate(item.tripDate)) || (item.islong != 1)"
              >
                修改行程
              </div>
              <div
                class="cklx"
                @click="cencleTrip(item)"
                style="margin-right: 5px"
                v-if="(!formatDate(item.tripDate)) || (item.islong != 1)"
              >
                {{ item.status == "3" ? "重新发布" : "取消行程" }}
              </div>
              <div class="cklx" @click="showTrip(item)">查看路线</div>
            </div>
          </div>
          <div class="list-item" v-else>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div>
                <div style="font-size: 16px; font-weight: 600">
                  {{ item.islong == 1 ? time2(item.tripDate) : '每天' }} {{ item.tripTime }}
                  <span
                    :style="
                      item.status == 3
                        ? 'background:gray;'
                        : (!formatDate(item.tripDate)) || (item.islong != 1)
                        ? 'background:green;'
                        : 'background:burlywood;'
                    "
                    style="
                      font-size: 12px;
                      color: #fff;
                      padding: 1px 5px;
                      border-radius: 3px;
                    "
                    >{{
                      item.status == 3
                        ? "已取消"
                        : (!formatDate(item.tripDate)) || (item.islong != 1)
                        ? "进行中"
                        : "已过期"
                    }}</span
                  >
                </div>
                <div>
                  <span style="margin-right: 5px"
                    ><van-tag type="primary"
                      >可坐{{ item.passengerNum }}人</van-tag
                    ></span
                  >
                  <span style="margin-right: 5px"
                    ><van-tag type="success">{{
                      formatCarNo(item.users.car_no)
                    }}</van-tag></span
                  >
                  <span
                    ><van-tag type="warning">{{ item.islong == 1 ? '单次行程' : '长期行程' }}</van-tag></span
                  >
                </div>
              </div>
              <div
                style="font-size: 22px; font-weight: 500"
                v-if="item.fee != null"
              >
                {{ item.fee
                }}<span style="font-size: 12px; margin-left: 2px">元</span>
              </div>
              <div style="font-size: 18px; font-weight: 600" v-else>面议</div>
            </div>
            <div
              style="
                margin-top: 15px;
                padding-bottom: 10px;
                border-bottom: 1px solid #f2f2f2;
              "
            >
              <div style="display: flex; font-size: 12px">
                <div>
                  <van-icon
                    name="location-o"
                    style="font-size: 14px; margin-right: 3px"
                  />起点：
                </div>
                <div>{{ item.startAddress }}</div>
              </div>
              <div
                style="display: flex; font-size: 12px; margin-top: 5px"
                v-if="item.tjd !== null && item.tjd !== ''"
              >
                <div>
                  <van-icon
                    name="location-o"
                    style="font-size: 14px; margin-right: 3px"
                  />途经：
                </div>
                <div style="display: flex; flex-direction: column">
                  <div
                    v-for="(ite, index) in JSON.parse(item.tjd)"
                    :key="index"
                  >
                    {{ ite.name }}
                  </div>
                </div>
              </div>
              <div style="display: flex; font-size: 12px; margin-top: 5px">
                <div>
                  <van-icon
                    name="location-o"
                    style="font-size: 14px; margin-right: 3px"
                  />终点：
                </div>
                <div>{{ item.endAddress }}</div>
              </div>
            </div>
            <div
              style="
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 10px; ;
              "
            >
            <div
                class="cklx"
                @click="shelTrip(item)"
                style="margin-right: 5px"
                v-if="(!formatDate(item.tripDate)) || (item.islong != 1)"
              >
                分享行程
              </div>
              <div
                class="cklx"
                @click="editTrip(item)"
                style="margin-right: 5px"
                v-if="(!formatDate(item.tripDate)) || (item.islong != 1)"
              >
                修改行程
              </div>
              <div
                class="cklx"
                @click="cencleTrip(item)"
                style="margin-right: 5px"
                v-if="(!formatDate(item.tripDate)) || (item.islong != 1)"
              >
                {{ item.status == "3" ? "重新发布" : "取消行程" }}
              </div>
              <div class="cklx" @click="showTrip(item)">查看路线</div>
              <!-- <div class="cklx">立即同行</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <empty
      :msg="'该区域暂无行程'"
      :show="sfcList.length == 0 ? true : false"
    ></empty>
    <loading :show="isShowLoading"></loading>
    <van-popup v-model="showTishi" position="top">
            <div style="width: 100%; text-align: right">
                <img
                    style="width: 55%"
                    src="./comm/tishi.png"
                    alt=""
                    @click="closeTiShi"
                />
            </div>
    </van-popup>
  </div>
</template>
<script>
import { Tag, Dialog, Toast,Popup } from "vant";
import Empty from "./comm/empty.vue";
import Loading from "./comm/loading.vue";
import { passenger } from "@/api";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);
const wx = window.wx;
export default {
  components: {
    Empty: Empty,
    Loading: Loading,
    VanTag: Tag,
    VanPopup: Popup,
  },
  data() {
    return {
      showTishi: false,
      activeId: "",
      active: 0,
      opacitys: 0,
      opacitys1: 0,
      items: [
        { text: "上城区" },
        { text: "下城区" },
        { text: "江干区" },
        { text: "拱墅区" },
        { text: "西湖区" },
        { text: "滨江区" },
        { text: "萧山区" },
        { text: "余杭区" },
        { text: "临平区" },
        { text: "富阳区" },
        { text: "临安区" },
      ],
      isShowLoading: true,
      value: 0,
      switch1: false,
      switch2: false,
      option: [
        { text: "全部区域", value: 0 },
        { text: "西湖区", value: 1 },
        { text: "滨江区", value: 2 },
      ],
      sfcList: [],
      myInfoType: null,
      myInfo: null,
      toDay: "",
      weatherInfo: null,
    };
  },


  mounted() {
    document.title = "行程";
    this.myInfoType = JSON.parse(localStorage.getItem("user")).types;
    this.myInfo = JSON.parse(localStorage.getItem("user"));
    setTimeout(() => {
      this.getwxSign()
    }, 1000);
    this.getPassengerTripList();
  },
  methods: {
    getformat(e) {
      return dayjs(e).format('MM月DD日')
    },
    shelTrip(e) {
      this.showTishi = true
      wx.showMenuItems({
        menuList: ["menuItem:share:appMessage"], // 要显示的菜单项，所有 menu 项见附录3
      });
      let _this = this
      setTimeout(() => {
        let urls = `https://blog.qiyasoft.top/login?resouce=info&id=${e.id}`
        wx.updateAppMessageShareData({
              title: `${e.islong == 1 ? _this.getformat(e.tripDate) : '每天'} ${e.tripTime}`, // 分享标题
              desc: `${e.types == '1' ? '乘客' : '车主'}发布了${e.startAddress}-${e.endAddress}的行程，点击查看`, // 分享描述
              // link: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf1e471795a1fd4e4&redirect_uri=${urls}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
              link: urls,
              imgUrl: "https://middle-ground.shulanhealth.com/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221025083427.jpg", // 分享图标
              success: function (res) {
                  this.showTishi = false;
                  if (res.err_msg == "shareWechatMessage:ok") {
                      Toast.success("分享成功");
                  }
              },
          });
      }, 1000); 
    },
    getwxSign() {
            let href =  encodeURIComponent(window.location.href.split('#')[0])
            // let signLink = /(Android)/i.test(navigator.userAgent) ? href : window.entryUrl
            let params = {
              url: href,
            }
            passenger.getWxSign(params).then(res => {
              if(res.data.code == 200) {
                wx.config({
                        //beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: "wxf1e471795a1fd4e4", // 必填，企业微信的corpID
                        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
                        signature: res.data.data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                        jsApiList: [
                          "onMenuShareTimeline",
                          "onMenuShareAppMessage",
                          "updateAppMessageShareData", //1.4 分享到朋友
                          "updateTimelineShareData", //1.4分享到朋友圈
                          "onMenuShareQQ",
                          "onMenuShareWeibo",
                          "onMenuShareQZone",
                          "hideMenuItems",
                          "showMenuItems",
                        ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                    });
                    wx.ready(function () {
                      wx.hideMenuItems({
                            menuList: [
                                "menuItem:share:appMessage",
                                "menuItem:share:timeline",
                                "menuItem:share:qq",
                                "menuItem:share:weiboApp",
                                "menuItem:share:appMessage",
                                "menuItem:copyUrl",
                                "menuItem:openWithQQBrowser",
                                "menuItem:openWithSafari",
                                "menuItem:share:brand",
                                "menuItem:share:QZone",
                                "menuItem:share:facebook",
                                "menuItem:favorite",
                                "menuItem:favorite",
                            ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有 menu 项见附录3
                        });
                        // alert("ready");
                        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                    });
                    wx.error(function (res) {
                        this.tsRemark = JSON.stringify(res);
                        alert(JSON.stringify(res), "error");
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                    });
              }
            })
    },
    formatDate(e) {
      // dayjs.extend(isSameOrAfter)
      let nowDate = dayjs().format("YYYY-MM-DD");
      return dayjs(nowDate).isAfter(dayjs(e, "day"));
    },
    formatCarNo(e) {
      const nBegin = e.substring(0, 2);
      const nEnd = e.substring(e.length - 2, e.length);
      return nBegin + "***" + nEnd;
    },
    goTo() {
      if (this.myInfoType == 1) {
        this.$router.push({
          name: "ckfb",
        });
      } else if (this.myInfoType == 2) {
        this.$router.push({
          name: "czfb",
        });
      }
    },
    goMy() {
      this.$router.push({
        name: "my",
      });
    },
    searchXc() {
      this.getPassengerTripList();
    },
    showTrip(e) {
      this.$router.push({
        name: "trip-info",
        query: {
          id: e.id,
        },
      });
    },
    editTrip(e) {
      if (e.types == "1") {
        this.$router.push({
          name: "ckfb",
          query: {
            id: e.id,
            isAdd: "EDIT",
          },
        });
      } else {
        this.$router.push({
          name: "czfb",
          query: {
            id: e.id,
            isAdd: "EDIT",
          },
        });
      }
    },
    cencleTrip(e) {
      Dialog.confirm({
        title: "温馨提示",
        confirmButtonText: "确定",
        confirmButtonColor: "#00B5A0",
        message: e.status == 1 ? "确定取消行程吗？" : "确定重新发布行程吗？",
      })
        .then(() => {
          if (e.status == 1) {
            let params = {
              id: e.id,
            };
            passenger.cencleTrip(params).then((res) => {
              if (res.data.code == 200) {
                Toast.success("取消成功");
                this.getPassengerTripList();
              }
            });
          } else {
            let params = {
              id: e.id,
            };
            passenger.againTrip(params).then((res) => {
              if (res.data.code == 200) {
                Toast.success("发布成功");
                this.getPassengerTripList();
              }
            });
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    onConfirm() {
      this.$refs.item.toggle();
    },
    closeTiShi() {
      this.showTishi = false
    },
    time2(data) {
      if (data) {
        if (data == this.dayjs().format("YYYY-MM-DD")) {
          return "今天";
        } else if (
          this.dayjs(data) > this.dayjs() &&
          this.dayjs(data) < this.dayjs().add(1, "day")
        ) {
          return "明天";
        } else {
          return this.dayjs(data).format("MM月DD日");
        }
      }
    },
    getPassengerTripList() {
      this.isShowLoading = true;
      let params = {
        page: 1,
        pageSize: 15,
        searchkey: "",
      };
      passenger.getMyFbList(params).then((res) => {
        this.sfcList = res.data.data;
        this.isShowLoading = false;
      });
    },
    // getPassengerTripDistrictList() {
    //   this.isShowLoading = true;
    //   let params = {};
    //   passenger.getPassengerTripDistrictList(params).then((res) => {
    //     for(let i in res.data.data) {
    //       for(let j in this.items) {
    //         if(res.data.data[i].endDistrict.substring(6,9) == this.items[j].text) {
    //           this.items[j].badge = res.data.data[i].count
    //         }
    //       }
    //     }
    //     this.isShowLoading = false;
    //   });
    // },
  },
  beforeRouteEnter(to, from, next) {
    console.log("*************qq***Test1****Enter**************");
    console.log("to", to);
    console.log("from", from);
    console.log("next", next);
    console.log(location.pathname);
    /* eslint-disable */
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        if(to.path !== location.pathname) {
          location.assign(to.fullPath);
        } else {
          next();
        }
    } else {
      next();
    }
  },
};
</script>
<style scoped>
/deep/ .van-popup {
    background: none;
}
/deep/ .van-tree-select__nav {
  flex: 0.5;
}
/deep/ .van-sidebar-item--select::before {
  background-color: #42c6ff;
}
.top-box {
  width: 90%;
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 21px;
  z-index: 2;
}
.top-box div p {
  font-size: 16px;
  color: #fafafa;
  line-height: 0;
}
.riqi {
  display: flex;
  align-items: center;
}
.riqi img {
  margin-right: 5px;
}
.riqi span {
  font-size: 13px;
  color: #fafafa;
}
.head-sty {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.list-item {
  width: 92%;
  margin-left: 4%;
  background: #fff;
  box-shadow: 0px 2px 10px -4px rgba(2, 66, 58, 0.2);
  border-radius: 8px;
  padding: 15px 15px 10px 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.cklx {
  border: 1px solid #2d83e8;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #2d83e8;
}
.bar-box {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.show-more {
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #2d83e8;
  margin-bottom: 10px;
  cursor: pointer;
}
.bar-item {
  flex: 1;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background: #fff;
  justify-content: center;
}
.bar-item img {
  width: 20px;
  margin-right: 5px;
}
</style>
